/* Project card */

import { Box, Button, Image, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import React from "react"

const StyledBox = styled(Box)`
  position: relative;

  &:hover .overlay {
    opacity: 1;
  }
`

const ProjectCard = ({ title, featured, slug }) => {
  return (
    <StyledBox
      mb={[4, 4, 4, 8]}
      _hover={{ transform: "scale(1.05)", transition: "all 0.2s ease-in-out" }}
    >
      <Image
        w="100%"
        h="auto"
        d="block"
        src={featured}
        alt={title}
        className="image"
      />
      <Box
        pos="absolute"
        bottom={0}
        background="rgba(0, 0, 0, 0.5)"
        width="100%"
        transition="0.5s ease"
        opacity="0"
        padding={4}
        textAlign="center"
        className="overlay"
      >
        <Text fontSize={["md", "lg"]} color="gray.50" my={2}>
          {title}
        </Text>
        <Button
          colorScheme="yellow"
          as={GatsbyLink}
          to={`/projects${slug}`}
          my={2}
        >
          View Project
        </Button>
      </Box>
    </StyledBox>
  )
}

export default ProjectCard
