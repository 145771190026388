/* Projects filter button */

import { Button } from "@chakra-ui/react"
import React from "react"

const ProjectFilterButton = ({
  currentFilter,
  setCurrentFilter,
  filter,
  isDisabled,
}) => {
  return (
    <Button
      variant="outline"
      isActive={currentFilter === filter}
      isDisabled={isDisabled}
      onClick={setCurrentFilter}
      colorScheme="teal"
      _active={{
        bgColor: "yellow.400",
        color: "gray.800",
      }}
      isFullWidth
    >
      {filter.charAt(0).toUpperCase() + filter.slice(1)}
    </Button>
  )
}

export default ProjectFilterButton
