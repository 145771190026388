/* Projects gallery page template */

import {
  Box,
  Container,
  Flex,
  Heading,
  Select,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import React from "react"
import Pagination from "../components/pagination"
import ProjectCard from "../components/project-card"
import ProjectFilterButton from "../components/project-filter-button"
import Seo from "../components/seo"

const filters = ["all", "installation", "painting", "remodeling", "repair"]

const ProjectsPageTemplate = ({ data, pageContext }) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)")

  const projects = data.allMarkdownRemark.nodes

  const { currentPage, numTotalPages } = pageContext
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === numTotalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/projects"
      : `/projects/page-${
          currentPage - 1 !== 0 ? (currentPage - 1).toString() : "1"
        }`
  const nextPage = `/projects/page-${(currentPage + 1).toString()}`

  const [currentFilter, setCurrentFilter] = React.useState("all")

  const filteredProjects = filter =>
    filter === "all"
      ? projects
      : projects.filter(project => project.frontmatter.type === filter)

  return (
    <>
      <Seo title="Projects" />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" mb={2} textAlign="center">
          Projects
        </Heading>
        <Text color="gray.50" textAlign="center">
          Some of our latest work
        </Text>
      </Flex>
      <Container maxW="container.xl" minH="50vh" py={[4, 8]}>
        {isLargerThan768 ? (
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={1}
            w="100%"
            justify="center"
            align="center"
            my={[4, 8]}
          >
            {filters.map((filter, index) => (
              <ProjectFilterButton
                key={index}
                currentFilter={currentFilter}
                setCurrentFilter={() => setCurrentFilter(filter)}
                filter={filter}
                isDisabled={filteredProjects(filter).length === 0}
              />
            ))}
          </Stack>
        ) : (
          <Select onChange={e => setCurrentFilter(e.target.value)} my={[4, 8]}>
            {filters.map((filter, index) => (
              <option
                key={index}
                value={filter}
                disabled={filteredProjects(filter).length === 0}
              >
                {filter.charAt(0).toUpperCase() + filter.slice(1)}
              </option>
            ))}
          </Select>
        )}
        {filteredProjects(currentFilter).length > 0 && (
          <Box
            sx={{
              columnCount: [1, 2, 2, 4],
              columnGap: ["16px", "16px", "16px", "32px"],
            }}
            my={[4, 8]}
          >
            {filteredProjects(currentFilter).length > 0 &&
              filteredProjects(currentFilter).map((project, index) => (
                <ProjectCard
                  key={index}
                  title={project.frontmatter.title}
                  slug={project.fields.slug}
                  featured={project.frontmatter.featured}
                />
              ))}
          </Box>
        )}
        {numTotalPages > 1 && (
          <Pagination
            nextPage={nextPage}
            prevPage={prevPage}
            numTotalPages={numTotalPages}
            currentPage={currentPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
            to="projects"
          />
        )}
      </Container>
    </>
  )
}

export const query = graphql`
  query Projects($limit: Int, $skip: Int) {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "projects" } } }
      limit: $limit
      skip: $skip
      sort: { fields: frontmatter___complete, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          description
          featured
          type
        }
        fields {
          slug
        }
      }
    }
  }
`

export default ProjectsPageTemplate
